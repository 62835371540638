<template>
  <div class="newUrlMobile">
    <div class="header">
      <img class="logo" :src="`${imgUrl}/img/container/big_logo.png`" />
    </div>
    <div class="sponsor">
      <img class="sponsor-img" :src="`${imgUrl}/img/container/sponsor.png`" />
    </div>

    <div class="content-mobile">
      <div
        class="mobile-cont-item"
        v-for="(item, index) in appDown"
        :key="index"
      >
        <div class="headportrait">
          <img :src="item.ranking" />
        </div>
        <div class="speed" :style="item.textcolor">{{ item.speed }}秒</div>
        <div class="circuit">APP通道{{ index + 1 }}</div>
        <div class="btns" @click="goto(item.domain_url)">立即下载</div>
      </div>

      <div
        class="mobile-cont-item"
        v-for="(item1, index1) in H5Down"
        :key="index1 + 6"
      >
        <div class="headportrait">
          <img :src="item1.ranking" />
        </div>
        <div class="speed" :style="item1.textcolor">{{ item1.speed }}秒</div>
        <div class="circuit">H5线路{{ index1 + 1 }}</div>
        <div class="btns" @click="goto(item1.domain_url)">立即进入</div>
      </div>
    </div>
  </div>
</template>

<script>
import { speed, speedCz, jumplink } from '../network/api'
import ping from '../assets/js/ping'
export default {
  name: 'newUrlMobile',
  components: {},
  data() {
    return {
      loading: '重新测速',
      down: [
        {
          img: `${this.imgUrl}/container/ios.png`,
          text: '苹果下载'
        },
        {
          img: `${this.imgUrl}/container/android.png`,
          text: '安卓下载'
        }
      ],
      urlData: [],
      bImg: [
        {
          img: `${this.imgUrl}/container/b_1.png`
        },
        {
          img: `${this.imgUrl}/container/b_2.png`
        },
        {
          img: `${this.imgUrl}/container/b_3.png`
        },
        {
          img: `${this.imgUrl}/container/b_4.png`
        }
      ],
      adDown: [],
      ioDown: [],
      addDown: [],
      appDown: [],
      H5Down: [],
      timets:null
    }
  },
  mounted() {
    window.clearInterval(this.timets)
    this.speed()
    // this.pingurl()
  },
  methods: {
    goto(url) {
      window.open(url)
    },
    pingurl() {
      this.appDown.forEach((v, i) => {
        ping(v.domain_url).then((res) => {
          v.speed = res / 1000
          v.speedms = res
          if (v.speedms < 1500) {
            v.ranking = require('../assets/img/container/s3.png')
            v.textcolor = 'color:#39b61d'
          } else if (v.speedms >= 1500 && v.speedms < 3000) {
            v.ranking = require('../assets/img/container/s2.png')
            v.textcolor = 'color:#f2a243'
          } else {
            v.ranking = require('../assets/img/container/s1.png')
            v.textcolor = 'color:#df2424'
          }
        })
      })

      this.H5Down.forEach((v, i) => {
        ping(v.domain_url).then((res) => {
          v.speed = res / 1000
          v.speedms = res

          if (v.speedms < 1500) {
            v.textcolor = 'color:#39b61d'
            v.ranking = require('../assets/img/container/s3.png')
          } else if (v.speedms >= 1500 && v.speedms < 3000) {
            v.textcolor = 'color:#f2a243'
            v.ranking = require('../assets/img/container/s2.png')
          } else {
            v.textcolor = 'color:#df2424'
            v.ranking = require('../assets/img/container/s1.png')
          }
        })
      })
      this.$forceUpdate()
    },

    speed() {
      speed().then((res) => {
        if (res.data.status_code === 200) {
          // res.data.res.forEach((item, index) => {
          //   let idx = item.domain_url.lastIndexOf('/')
          //   let arr = item.domain_url.split('')
          //   arr.splice(idx + 1, 0, 'm.')
          //   let newArr = arr.join('')
          //   res.data.res[index].domain_url = newArr
          // })

          this.urlData = res.data.res

          res.data.res.forEach((v, i) => {
            v.ranking = require('../assets/img/container/s3.png')
            v.textcolor = 'color:#39b61d'
            v.speed = 0
            if (v.key == 'appDown') {
              this.appDown.push(v)
            } else if (v.key == 'h5') {
              this.H5Down.push(v)
            }
          })
          this.pingurl()
          this.timets = setInterval(() => {
            this.pingurl()
          }, 5000)

        }
      })
    },

    gotoDown() {
      window.open(this.addDown)
    }
  }
}
</script>

<style scoped lang="less">
.newUrlMobile {
  height: 100vh;
  background: url('https://sabbont.gz.bcebos.com/paspages/img/container/mobilebg.png') no-repeat;
  // background-size: 100% 100%;
  background-position: top center;
  background-size: contain;
  .header {
    width: 100%;
    height: 90px;
    position: relative;
    background-color: transparent !important;
    .logo {
      width: 120px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .sponsor {
    width: 85%;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    img {
      width: 100%;
    }
  }

  .content-mobile {
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;

    .mobile-cont-item {
      width: 30%;
      background-color: rgba(240, 239, 241, 0.8);
      border-radius: 10px;
      padding: 10px 0;
      margin: 5px 0;
      margin-right: 2%;
      margin-left: 2%;

      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n-2) {
        margin-left: 1%;
      }
      .headportrait {
        margin: 0 auto;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .speed {
        font-size: 18px;
        text-align: center;
        margin: 6px 0;
        color: red;
      }

      .circuit {
        font-size: 14px;
        color: #2454df;
        text-align: center;
      }

      .btns {
        width: 80%;
        height: 25px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        border-radius: 8px;
        line-height: 25px;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
